<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH MEMBERS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-combobox
          class="mt-6"
          :append-icon="icons.mdiAccountSearchOutline"
          v-model="search"
          @keyup="searching($event.target.value)"
          @change="get_search_items_info"
          :items="search_items"
          item-value="id"
          item-text="company_name"
          placeholder="Search by (Account Name/Account #)"
          ref="memberCombobox"
          dense
          outlined
        ></v-combobox>
      </v-card-text>
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account
            :keyyy="keyyy"
            :data="member_information"
          ></account>
        </v-tab-item>
        <v-tab-item>
          <induction
            :keyyy="keyyy"
            :data="induction_history"
            :member_info="member_information"
          ></induction>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiInformationOutline, mdiAccountCashOutline
    , mdiCash, mdiCellphone, mdiClipboardClockOutline, mdiContentSaveMoveOutline,
    mdiCashRefund, mdiShieldAccount, mdiGoogleCirclesCommunities, mdiBallotOutline,
    mdiCharity, mdiHandshakeOutline, mdiCubeScan, mdiGift,
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import Account from './member_components/Account'
  import Induction from './member_components/Induction'

  export default {
    components: {
      Account,
      Induction,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiCharity,
          mdiHandshakeOutline,
          mdiCubeScan,
          mdiGift,
        },
      }
    },
    data() {
      return {
        search: '',
        search_member: '',
        search_items: [],
        keyyy:0,
        member_information: {},
        induction_history: [],
        tab: '',
        tabs: [
          {title: 'INFORMATION', icon: mdiAccountOutline},
          {title: 'PAYMENT HISTORY', icon: mdiGoogleCirclesCommunities},
        ],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
          this.member_information = {}
          this.induction_history = []
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position',]),
    },
    methods: {
      ...mapActions('members_information', ['search_member_data']),
      searching(value) {
        this.keyyy++;
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_member_data({
          search_word: value,
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      reset() {
        this.member_information = {}
        this.induction_history = []
      },
      get_search_items_info() {
        this.keyyy++;
        this.member_information = {}
        this.induction_history = []
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.member_information = this.search_items[index]
            this.induction_history = this.search_items[index].member_payment
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
    }
  }
</script>
